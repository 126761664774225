@font-face {
  font-family: 'Domine';
  src: url('../fonts/Domine-Regular.woff2') format('woff2'),
  url('../fonts/Domine-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Open Sans';
  src: url('../fonts/OpenSans-Regular.woff2') format('woff2'),
  url('../fonts/OpenSans-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Open Sans';
  src: url('../fonts/OpenSans-Medium.woff2') format('woff2'),
  url('../fonts/OpenSans-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Open Sans';
  src: url('../fonts/OpenSans-SemiBold.woff2') format('woff2'),
  url('../fonts/OpenSans-SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
