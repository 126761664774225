.p-splitter-panel-nested {
  overflow: scroll;
  padding: 10px 10px 0px 10px;
}

.p-datatable-table {
  width: 100%;
}

.p-splitter .p-splitter-gutter {
  z-index: 100;
}


.p-progressbar .p-progressbar-value {
  background: #009079 !important;
}

.p-sidebar-top {
  height: auto !important;
}


.p-sidebar-content {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.p-fieldset {
  max-width: calc(100vw - 10px);
}


.p-messages .p-component {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.p-datatable .p-datatable-wrapper {
  //overflow: initial !important;
}

.p-splitter-panel-nested{
  overflow: auto;
}
@media (max-width: 960px) {
  .display-table-cell {
    .p-datatable-tbody > tr > td {
      display: table-cell !important;
      border: 1px solid #dee2e6 !important;
    }
  }
}
