.name-group {
  display: inline-flex;
  vertical-align: top;
  color: gray
}


.border-group {
  border: 1px solid #dee2e6;
  padding: 10px;
}


.divider {
  width: 100%;
}

.filter-container {
  padding: 12px;
  border-radius: 5px;
  border: 1px gainsboro solid;
  overflow: auto;
  max-height: calc(100vh - 180px);
  height: -moz-fit-content;
  height: fit-content;
}

.rt-table-moving__item-static_readonly {
  cursor: pointer !important;
  &:hover {
    background: rgba(0, 0, 0, 0.07) !important;
  }
}
