.remove-padding {
  .mat-dialog-content {
    padding: 0;
  }
}

.remove-padding-field {
  .mat-form-field-wrapper {
    padding: 0;
    margin: 0 !important;
  }


  .mat-form-field-infix {
    border: 0;
    padding: 17px 0 12px !important;
    justify-content: center;
    display: flex;
    align-items: center;
    margin: 0;
  }

}

.mat-checkbox-checked.mat-primary .mat-checkbox-background {
  background: #009079 !important;
}


.mat-checkbox-indeterminate.mat-primary .mat-checkbox-background, .mat-checkbox-checked.mat-primary .mat-checkbox-background {
  background: #009079 !important;
}

.mat-progress-spinner circle, .mat-spinner circle {
  stroke: #009079 !important;
}

.remove-padding-sheet {
  .mat-bottom-sheet-container-medium {
    padding: 0;
  }
}


.custom-button.add-button {
  min-height: 40px;
  text-align: center;
  cursor: copy;
  border: 1px gainsboro solid;
  box-shadow: none !important;

  .loader {
    left: calc(50% - 10px);
  }
}


.custom-button.abstract {
  min-height: 40px;
  text-align: center;
  border: 1px gainsboro solid;
  box-shadow: none !important;

  .loader {
    left: calc(50% - 10px);
  }
}


.full-input{
  width: 100%;
}
