@use '@angular/material' as mat;
@import '~bootstrap/dist/css/bootstrap-grid.css';

@import "assets/customStyles/primeng";
@import "assets/customStyles/elements";
@import "assets/customStyles/material";
@import "assets/customStyles/fonts";

@include mat.core();
$primary: (
        50 : #9bfff2,
        100 : #7aefe4,
        200 : #55cebf,
        300 : #00a591, // no
        400 : #009986,
        500 : #008b7a, // no
        600 : #007b6b,
        700 : #006d5f, // no
        800 : #005f53,
        900 : #005349,
        A100 : #004840, // no
        A200 : #003e38, // no
        A400 : #003833, // no
        A700 : #00332f, // no
        contrast: (
                50 : #000000,
                100 : #000000,
                200 : #000000,
                300 : #ffffff,
                400 : #ffffff,
                500 : #ffffff,
                600 : #ffffff,
                700 : #ffffff,
                800 : #ffffff,
                900 : #ffffff,
                A100 : #000000,
                A200 : #000000,
                A400 : #000000,
                A700 : #000000,
        )
);
$accent: (
        50 : #FEF2DF,
        100 : #FDDEB0,
        200 : #b4ebc9, // no
        300 : #F9B249,
        400 : #F8A320,
        500 : #F79200,
        600 : #F38600,
        700 : #F38600, // no
        800 : #F38600, // no
        900 : #F38600, // no
        A100 : #F38600, // no
        A200 : #F38600, // no
        A400 : #F38600, // no
        A700 : #F38600, // no
        contrast: (
                50: #000000,
                100: #000000,
                200: #000000,
                300: #000000,
                400: #000000,
                500: #000000,
                600: #000000,
                700: #000000,
                800: #000000,
                900: #000000,
                A100: #000000,
                A200: #000000,
                A400: #000000,
                A700: #000000,
        ),
);
$warn: (
        50 : #ffeeeb,
        100 : #ffd4cd,
        200 : #ffb8ab,
        300 : #ff6060,
        400 : #ec4040,
        500 : #d02828,
        600 : #c32222,
        700 : #be1f1f,
        800 : #b21b1b,
        900 : #a01818,
        A100 : #ffffff,
        A200 : #ffffff,
        A400 : #ffdbd8,
        A700 : #ffc4be,
        contrast: (
                50: #000000,
                100: #000000,
                200: #000000,
                300: #000000,
                400: #ffffff,
                500: #ffffff,
                600: #ffffff,
                700: #ffffff,
                800: #ffffff,
                900: #ffffff,
                A100: #000000,
                A200: #000000,
                A400: #000000,
                A700: #000000,
        ),
);
$twinkle-primary: mat.define-palette($primary);
$twinkle-accent: mat.define-palette($accent);
$twinkle-warn: mat.define-palette($warn);

$twinkle-typography: mat.define-typography-config(
        $font-family: 'Roboto, "Helvetica Neue", sans-serif',
        $body-1: mat.define-typography-level(16px, 32px, 400, $letter-spacing: -0.03em),
);

$twinkle-theme: mat.define-light-theme((
        color: (
                primary: $twinkle-primary,
                accent: $twinkle-accent,
                warn: $twinkle-warn,
        ),
        typography: $twinkle-typography,
));
@include mat.all-component-themes($twinkle-theme);

body {
  @include mat.typography-level($twinkle-typography, 'body-1');
  font-size: 10px;
  color: var(--text-primary);
  margin: 0;
  padding: 0;
}

[data-type='text-es'] .mat-form-field,
[data-type='textarea'] .mat-form-field,
[data-type='text'] .mat-form-field,
[data-type='select'] .mat-form-field,
[data-type='customDate'] .mat-form-field,
[data-type='mask'] .mat-form-field {
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  width: 100%;
}

[data-type='text-es'] .mat-form-field-underline,
[data-type='textarea'] .mat-form-field-underline,
[data-type='text'] .mat-form-field-underline,
[data-type='select'] .mat-form-field-underline,
[data-type='customDate'] .mat-form-field-underline,
[data-type='mask'] .mat-form-field-underline {
  // display: none;
}

[data-type='text'] .mat-form-field-infix,
[data-type='mask'] .mat-form-field-infix,
[data-type='select'] .mat-form-field-infix,
[data-type='customDate'] .mat-form-field-infix,
[data-type='textarea'] .mat-form-field-infix {
  min-width: auto;
  width: auto;
  border-top: 0;
  display: flex;
  align-items: center;
}

[data-type='text'] .mat-form-field,
[data-type='mask'] .mat-form-field,
[data-type='select'] .mat-form-field {
  width: 100%;
  margin-top: 0;
}

[data-type='text'] .mat-form-field-appearance-legacy .mat-form-field-wrapper,
[data-type='mask'] .mat-form-field-appearance-legacy .mat-form-field-wrapper,
[data-type='select'] .mat-form-field-appearance-legacy .mat-form-field-wrapper {
  margin-bottom: -1.25em;
}

[data-type='text'] input,
[data-type='mask'] input [data-type='select'] input {
  min-height: 1.275rem;
}

* {
  box-sizing: border-box;
}


button:focus,
button:active:focus,
button.active:focus {
  outline: none !important;
  outline-style: none !important;
}


html,
body {
  height: 100%;
  overflow: hidden;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

* {
  transition: all .3s ease-in-out;
  -webkit-transition: background-color .3s linear;
  -ms-transition: background-color .3s linear;
}

input, textarea {
  font-size: 16px !important;
}

::-webkit-scrollbar {
  width: 5px; /* задає ширину полоси прокрутки */
  height: 5px;

}

::-webkit-scrollbar-track {
  background-color: rgb(234, 234, 234); /* задає колір тла */

}

::-webkit-scrollbar-thumb {
  background-color: rgb(204, 204, 204); /* задає колір полоси прокрутки */
}


.mat-mdc-option[aria-disabled=true].contains-mat-select-search {
  background: white;
}


.cdk-overlay-pane {
  min-width: 200px;
}

:root {
  --primary-200: #009179 !important;
  --primary-800: #00473b !important;
  --text-border-light: #E5E5E5;
  --text-background: #F7F7F7;
}
